import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { cardNetworkNames } from '../../Add/utils'
import Currency from '../../../../components/Format/Currency/Currency'
import { useLanguageState } from '../../../../stores/language/LanguageStore'
import { SetTotalDetails } from '../../../../types/paymentInstruction'
import { pi } from '../../../../lang/definitions'
import { useSession } from 'stores/session'
import usePaymentsSummaryStyle from './PaymentsSummary.style'
import { cx } from 'antd-style'
import { useTranslation } from 'utils/helpers'

interface Sums {
  [currency: string]: {
    amount: number
    fee: number
    total: number
  }
}

interface CurrencyDetail {
  currency: string
  value: number
  feeFixed?: number
  feePercentAmount?: number
  count: number
  countFeeFixed?: number
}

interface CardDetails {
  [card: string]: {
    cardNetwork: string
    sourceTitle?: string
    amounts?: CurrencyDetail[]
    fees?: CurrencyDetail[]
  }
}

interface PaymentsSummaryProps {
  setTotalDetails: SetTotalDetails
  title:
    | 'pi.add.reviewPayments.paymentSummary.header.pi'
    | 'pi.add.reviewPayments.paymentSummary.header.template'
    | 'pi.add.reviewPayments.paymentSummary.header.pi.approver'
    | 'pi.add.reviewPayments.paymentSummary.header.template.approver'
}

const PaymentsSummary = ({ setTotalDetails, title }: PaymentsSummaryProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { styles } = usePaymentsSummaryStyle()
  const t = useTranslation()

  const intl = useIntl()

  const [numberOfPayments, setNumberOfPayments] = useState(0)
  const [currencies, setCurrencies] = useState<string[]>([])
  const [cards, setCards] = useState<string[]>([])
  const [sums, setSums] = useState<Sums>()
  const [isCorp, setIsCorp] = useState<boolean>(false)
  const [cardDetails, setCardDetails] = useState<CardDetails>()
  const [isAmountDetailsShown, setIsAmountDetailsShown] = useState(false)
  const [isFeeDetailsShown, setIsFeeDetailsShown] = useState(false)
  const [feePercentage, setFeePercentage] = useState(0)
  const [feeFixed] = useState(0)
  const { state: sessionState } = useSession()
  const user = sessionState.user!

  useEffect(() => {
    const activeProfile = user.profiles.find((profile) => profile.id === user.activeProfileId)
    const entityObject = activeProfile?.entity.class
    if (Object.keys(entityObject!)[0] === 'person' || Object.keys(entityObject!)[0] === 'sme') {
      setIsCorp(false)
    } else {
      setIsCorp(true)
    }
  }, [])

  useEffect(() => {
    let numberOfPayments = 0
    const sums: Sums = {}

    const cardDetails: CardDetails = {}

    numberOfPayments += setTotalDetails.count

    setTotalDetails.sums.forEach((sum) => {
      sum.amountDetails.forEach((detail) => {
        const { bin, last4, currency, value, count } = detail
        const card = `${bin}-${last4}`
        if (cardDetails[card]) {
          cardDetails[card] = {
            ...cardDetails[card],
            amounts: [...cardDetails[card].amounts!, { currency, value, count }],
          }
        } else {
          cardDetails[card] = {
            sourceTitle: detail.sourceTitle,
            cardNetwork: detail.cardNetwork,
            amounts: [{ currency, value, count }],
            fees: [],
          }
        }
      })

      sum.feeDetails.forEach((detail) => {
        const { bin, last4, currency, value, feeFixed, feePercentAmount, count, countFeeFixed } = detail
        const card = `${bin}-${last4}`

        if (cardDetails[card]) {
          cardDetails[card] = {
            ...cardDetails[card],
            fees: [...cardDetails[card].fees!, { currency, value, feeFixed, feePercentAmount, count, countFeeFixed }],
          }
        } else {
          cardDetails[card] = {
            sourceTitle: detail.sourceTitle,
            cardNetwork: detail.cardNetwork,
            fees: [{ currency, value, feeFixed, feePercentAmount, count, countFeeFixed }],
            amounts: [],
          }
        }
      })

      sums[sum.currency] = {
        amount: sum.amount,
        fee: sum.fee,
        total: sum.amount + sum.fee,
      }
    })

    const currencies = Object.keys(sums).sort()
    const cards = Object.keys(cardDetails).sort()

    setCurrencies(currencies)
    setCards(cards)
    setNumberOfPayments(numberOfPayments)
    setCardDetails(cardDetails)
    setSums(sums)

    const fee = sums[Object.keys(sums)[0]].fee
    const amount = sums[Object.keys(sums)[0]].amount
    setFeePercentage(getFeePercentage(fee, amount))
  }, [setTotalDetails])

  const getFeePercentage = (fee: number, amount: number) => {
    return +((fee / amount) * 100).toFixed(2)
  }

  const handleToggleAmountDetails = () => {
    setIsAmountDetailsShown(!isAmountDetailsShown)
  }
  const handleToggleFeeDetails = () => {
    setIsFeeDetailsShown(!isFeeDetailsShown)
  }

  const renderFeePercentage = (percentageFee: number, fixedFee: number) => {
    if (fixedFee === 0) {
      return <span>{' (' + percentageFee + '%)'}</span>
    } else {
      const resultFixedFee = fixedFee / 100
      return <span>{' (' + percentageFee + '% + ' + resultFixedFee + ')'}</span>
    }
  }

  const renderCardDetails = (card: string): React.ReactNode => (
    <div className={styles.rowLabel}>
      <span className={styles.cardTitle}>
        {(cardDetails![card].sourceTitle || cardNetworkNames[cardDetails![card].cardNetwork]) +
          (!isCorp
            ? ' (' + getFeePercentage(cardDetails![card].fees![0].value, cardDetails![card].amounts![0].value) + '%)'
            : '')}
      </span>
      <span className={styles.cardNumber}>
        {(cardDetails![card].sourceTitle ? cardNetworkNames[cardDetails![card].cardNetwork] + ' ' : '') +
          card.replace('-', ' •••• •••• ')}
      </span>
    </div>
  )

  const renderCardCurrencyDetails = (details: CurrencyDetail[]): React.ReactNode => (
    <div className={cx('amount-row', styles.rowData, styles.rowAmount)}>
      {currencies.map((currency: string) => {
        const { value, feeFixed, feePercentAmount, countFeeFixed } =
          details.find((currencyDetail: CurrencyDetail) => currencyDetail.currency === currency) || {}
        return (
          <>
            {feeFixed ? (
              <div className={cx(`fixed-fee-col-${currency}`, styles.column, styles.columnAmount)}>
                <div className={styles.innerRow}>
                  <span>{`${countFeeFixed} x ${intl.formatMessage(pi['pi.feeFixed'])}`}</span>
                  <Currency value={feeFixed.toString()} locale={language} currency={currency} />
                </div>
              </div>
            ) : null}
            {feePercentAmount && feeFixed ? (
              <div className={cx(`percent-fee-col-${currency}`, styles.column, styles.columnAmount)}>
                <div className={styles.innerRow}>
                  <span>{intl.formatMessage(pi['pi.feePercent'])}</span>
                  <Currency value={feePercentAmount.toString()} locale={language} currency={currency} />
                </div>
              </div>
            ) : null}
            {!feeFixed || feeFixed === 0 ? (
              <div className={cx(`value-${currency}`, styles.column, styles.columnAmount)}>
                {value && <Currency value={value.toString()} locale={language} currency={currency} />}
              </div>
            ) : null}
          </>
        )
      })}
    </div>
  )

  return (
    <div className={styles.container} data-testid="payment-summary-wrapper">
      {!!(sums && numberOfPayments) && (
        <React.Fragment>
          <div className={styles.summaryLabel}>
            <span>{t(title, { numberOfPayments })}</span>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.rowData}>
              {currencies.map(
                (currency: string): React.ReactNode => (
                  <div key={`currency-${currency}`} className={cx(styles.column, styles.currency, styles.row)}>
                    <span>{currency.toUpperCase()}</span>
                  </div>
                )
              )}
              <div className={styles.buttonContainer}>
                <button type="button">&nbsp;</button>
              </div>
            </div>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.rowLabel}>
              <span>
                {intl.formatMessage(pi['pi.add.reviewPayments.paymentSummary.numberOfPayments'], { numberOfPayments })}
              </span>
            </div>
            <div className={styles.rowData}>
              {currencies.map(
                (currency: string): React.ReactNode => (
                  <div key={`amount-${currency}`} className={cx(styles.column, styles.row)}>
                    <Currency value={sums[currency].amount.toString()} locale={language} currency={currency} />
                  </div>
                )
              )}
              <div className={styles.buttonContainer}>
                <button type="button" onClick={handleToggleAmountDetails}>
                  {isAmountDetailsShown ? <UpOutlined /> : <DownOutlined />}
                </button>
              </div>
            </div>
          </div>
          <div className={cx(styles.summaryRow, isAmountDetailsShown ? styles.active : styles.hidden)}>
            {cardDetails &&
              cards.map(
                (card: string): React.ReactNode => (
                  <div key={`card-${card}`} className={cx(styles.summaryRow, styles.details)}>
                    {renderCardDetails(card)}
                    {renderCardCurrencyDetails(cardDetails[card].amounts!)}
                    <div className={styles.buttonContainer}>
                      <button type="button">&nbsp;</button>
                    </div>
                  </div>
                )
              )}
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.rowLabel}>
              <span>
                {intl.formatMessage(pi['pi.add.reviewPayments.paymentSummary.serviceFee'], { numberOfPayments })}
              </span>
              {!isCorp && renderFeePercentage(feePercentage, feeFixed)}
            </div>
            <div className={styles.rowData}>
              {currencies.map(
                (currency: string): React.ReactNode => (
                  <div key={`fee-${currency}`} className={cx(styles.column, styles.row)}>
                    <Currency value={sums[currency].fee.toString()} locale={language} currency={currency} />
                  </div>
                )
              )}
              <div className={styles.buttonContainer}>
                <button type="button" onClick={handleToggleFeeDetails}>
                  {isFeeDetailsShown ? <UpOutlined /> : <DownOutlined />}
                </button>
              </div>
            </div>
          </div>
          <div className={cx(styles.summaryRow, isFeeDetailsShown ? styles.active : styles.hidden)}>
            {cardDetails &&
              cards.map(
                (card: string): React.ReactNode => (
                  <div key={`card-${card}`} className={cx(styles.summaryRow, styles.details)}>
                    {renderCardDetails(card)}
                    {renderCardCurrencyDetails(cardDetails[card].fees!)}
                    <div className={styles.buttonContainer}>
                      <button type="button">&nbsp;</button>
                    </div>
                  </div>
                )
              )}
          </div>
          <div className={cx(styles.summaryRow, styles.total)}>
            <div className={styles.rowLabel}>
              <span>{intl.formatMessage(pi['pi.add.reviewPayments.paymentSummary.total'], { numberOfPayments })}</span>
            </div>
            <div className={styles.rowData}>
              {currencies.map(
                (currency: string): React.ReactNode => (
                  <div key={`total-${currency}`} className={cx(styles.column, styles.row)}>
                    <Currency value={sums[currency].total.toString()} locale={language} currency={currency} />
                  </div>
                )
              )}
              <div className={styles.buttonContainer}>
                <button type="button">&nbsp;</button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default PaymentsSummary
