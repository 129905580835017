import React from 'react'
import { Source } from 'types/source'
import useCreditCardStyles from './CreditCard.style'
import { getCardIcon } from 'utils/card'
import { useTranslation } from 'utils/helpers'

interface CreaditCardProps {
  card: Source
}

const CreditCard = ({ card }: CreaditCardProps): React.JSX.Element => {
  const { styles } = useCreditCardStyles()
  const t = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.cardDetailsWrapper}>
        <div className={styles.cardTitle} data-testid="credit-card-title">
          <span data-testid="card-title">{card.title}</span>
        </div>
        <div className={styles.cardDetails}>
          <div>
            <img src={getCardIcon(card.typeProperties.cardNetwork, card.type)} alt="card" width={30} />
          </div>
          <div className={styles.cardNumber}>
            {card.type === 'vcn' ? (
              <span data-testid="airplus-credit-card-number">{t('card.virtualCard.airplus')}</span>
            ) : (
              <span data-testid="credit-card-number">{`${card.typeProperties.bin} •••• •••• ${card.typeProperties.last4}`}</span>
            )}
          </div>
          {card.preferredCurrency && (
            <div data-testid="credit-card-currency" className={styles.cardCurrency}>
              {card.preferredCurrency.toUpperCase()}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreditCard
