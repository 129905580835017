import React from 'react'
import { Profile } from '../../../types/profile'
import { formatLocale, FormatLocaleProps } from '../../../utils/formatLocale'
import { useSession } from 'stores/session'

const Currency = (props: FormatLocaleProps): React.JSX.Element => {
  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const { activeProfileId, profiles } = user
  const elementType: string = props.elementType || 'div'

  const isCorpUser = (profiles: Profile[], activeProfileId: string): boolean => {
    const activeProfileClass = profiles.find((x) => x.id === activeProfileId)?.entity.class
    if (activeProfileClass && activeProfileClass['corp']) {
      return true
    } else {
      return false
    }
  }

  const renderCurrencySign = (finalCurrencyValue: string): React.JSX.Element => {
    let output = <span></span>
    const currency = props.currency?.toLowerCase()

    switch (currency) {
      case 'eur':
        output = (
          <span>
            <span style={{ fontSize: '90%' }}>&#8364;</span>
            {finalCurrencyValue}
          </span>
        )
        break
      case 'usd':
      case 'cad':
      case 'aud':
        output = (
          <span>
            <span style={{ fontSize: '90%' }}>&#36;</span>
            {finalCurrencyValue}
          </span>
        )
        break
      case 'sek' || 'nok' || 'dkk':
        output = output = (
          <span>
            {finalCurrencyValue} <span style={{ fontSize: '90%' }}>&#107;&#114;</span>
          </span>
        )
        break
      case 'gbp':
        output = (
          <span>
            <span style={{ fontSize: '90%' }}>&#163;</span>
            {finalCurrencyValue}
          </span>
        )
        break

      default:
        output = (
          <span>
            <span style={{ fontSize: '90%' }}>{props.currency}</span>
            {finalCurrencyValue}
          </span>
        )
        break
    }
    return output
  }

  const renderCurrency = (props: FormatLocaleProps): React.ReactNode => {
    const finalCurrencyValue = formatLocale('currency', props)
    if (!isCorpUser(profiles, activeProfileId || '')) {
      return React.createElement(elementType, { className: 'amount-content' }, renderCurrencySign(finalCurrencyValue))
    } else {
      return React.createElement(
        elementType,
        { className: 'amount-content' },
        React.createElement('span', { style: { fontSize: '80%' } }, props.currency?.toUpperCase()),
        ' ', // Space between elements
        finalCurrencyValue
      )
    }
  }

  // return <div>{renderCurrency(props)}</div>
  return React.createElement(
    elementType,
    null, // No props in this case
    renderCurrency(props)
  )
}

export default Currency
