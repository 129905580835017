import React, { Key, useEffect, useState } from 'react'
import { Table, Button, Modal, notification } from 'antd'
import { useIntl } from 'react-intl'
import { ExclamationCircleOutlined, DeleteOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { TablePaginationConfig } from 'antd/lib/table'
import { beneficiaryColumns } from './columns'
import { defaultBeneficiary } from './utils'
import { useLanguageState } from '../../stores/language/LanguageStore'
import ActionMenu, { Action } from '../../components/ActionMenu/ActionMenu'
import Page, { MobileMenuOption } from '../../components/Page/Page'
import Filter, { FilterBody, FilterOption } from '../../components/Filter/Filter'
import ActionPage from '../../components/ActionPage/ActionPage'
import Loader from '../../components/Loader/Loader'
import * as api from '../../api/beneficiary'
import * as rulesApi from '../../api/rules'
import { Beneficiary, DeleteBeneficiariesResponse } from '../../types/beneficiary'
import { QueryParams } from '../../types/general'
import { page, action, beneficiaryStrings, filters, messages } from '../../lang/definitions/index'
import { getActiveUser, useTranslation } from '../../utils/helpers'
import ACL from '../../components/ACL/ACL'
import { FilterState } from '../../types/general'
import SecondaryMenu from '../../components/SecondaryMenu/SecondaryMenu'
import { SupplierFunded } from '../../types/rules'
import { DEVICE_TYPE } from '../../utils/getDeviceType'
import { resetDrawerHash, setDrawerHash } from '../../components/Drawers/utils'
import { useHistory } from 'react-router-dom'
import NewBeneficiaryDetails from './NewBeneficiaryDetails'
import RecipientsDrawer from './RecipientsDrawer'
import * as Sentry from '@sentry/react'
import acl from 'utils/acl'
import { isDeviceType } from 'hooks'
import { useSession } from 'stores/session'
import useBeneficiariesStyle from './Beneficiaries.style'
import { cx } from 'antd-style'

const PAGE_SIZE = 10

const Beneficiaries: React.FC = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const { styles } = useBeneficiariesStyle()
  const [languageState] = useLanguageState()
  const language = languageState.language

  const {
    state: { rules, ...sessionState },
    actions: { setRules },
  } = useSession()
  const me = sessionState.user!
  const iam = sessionState.iam!

  const t = useTranslation()

  const { profileId, entityId } = getActiveUser(me)

  const [showLoader, setShowLoader] = useState(false)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filterState, setFilterState] = useState<FilterState>()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [isDeleteBeneficiaryWindowOpen, setIsDeleteBeneficiaryWindowOpen] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>()
  const [beneficiary, setBeneficiary] = useState<Beneficiary>(defaultBeneficiary)
  const [total, setTotal] = useState<number>()

  const [beneficiariesFilter, setBeneficiariesFilter] = useState<FilterBody>()
  const [showClearFilter, setShowClearFilter] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState<string>(
    intl.formatMessage(beneficiaryStrings['beneficiary.not.added'])
  )
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<Beneficiary['id'][]>([])
  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)

  const hasDeleteEnabled = acl({
    iam,
    me,
    kind: 'beneficiary',
    barracksId: me.activeProfileId,
    action: 'delete',
  })

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, beneficiaries])

  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash

    if (windowHash && beneficiaries) {
      const isDetails = windowHash.includes('details')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]

      isDetails && openDetails(hashId)
    }
  }

  useEffect(() => {
    if (beneficiariesFilter && Object.keys(beneficiariesFilter).length > 0) {
      setShowClearFilter(true)
    } else {
      setShowClearFilter(false)
    }
  }, [beneficiariesFilter])

  const getBeneficiaries = async (params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      const { beneficiaries, total } = await api.getBeneficiaries(params)
      setBeneficiaries(beneficiaries)
      setTotal(total)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const searchBeneficiaries = async (filter: FilterBody, params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      const { beneficiaries, total } = await api.searchBeneficiaries(filter, params)
      setBeneficiaries(beneficiaries)
      setTotal(total)
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.beneficiary.search']),
        placement: 'topRight',
      })
    } finally {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    if (beneficiariesFilter) {
      void searchBeneficiaries(beneficiariesFilter, params)
    } else {
      void getBeneficiaries(params)
    }
  }, [beneficiariesFilter])

  const getActions = () => {
    const actions: Array<Action> = [
      {
        id: 'add-beneficiary',
        label: intl.formatMessage(action['action.beneficiary.add']),
        type: 'primary',
        callback: () => {
          setDrawerHash(history, '#drawer-new-beneficiary')
        },
        requiredACL: [{ kind: 'beneficiary', action: 'create' }],
      },
    ]
    if (hasDeleteEnabled && selectedRowsToDelete.length) {
      actions.push({
        id: 'delete-card',
        label: t('card.form.delete'),
        type: 'button',
        elementType: 'danger',
        badge: { count: selectedRowsToDelete.length, color: '#D66666' },
        requiredACL: [{ kind: 'beneficiary', action: 'delete' }],
        callback: () => showDeleteConfirmationModal(selectedRowsToDelete),
      })
    }
    return actions
  }

  const actionMenu = (
    <ActionMenu
      isOpen={isActionMenuOpen}
      actions={getActions()}
      label={intl.formatMessage(action['action.beneficiary.page.title'])}
      closeMenu={() => setIsActionMenuOpen(false)}
    />
  )

  const handleFilterChange = (value: string): void => {
    let filter: FilterBody | undefined = undefined
    if (value.length) {
      filter = {
        titleLike: value,
        accountNumberLike: value,
        countryLike: value,
        currencyLike: value,
        ringFence: {
          profileId: [profileId],
        },
      }

      setEmptyMessage(intl.formatMessage(beneficiaryStrings['beneficiary.not.found']))
    } else {
      setEmptyMessage(intl.formatMessage(beneficiaryStrings['beneficiary.not.added']))
    }

    setBeneficiariesFilter(filter)
    handleSetFilterState(value, 'search')
  }

  const filterOptions: Array<FilterOption> = [
    {
      label: intl.formatMessage(filters['filter.search']),
      type: 'search',
      callback: (value): void => handleFilterChange(value as string),
    },
  ]

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const filter = (
    <Filter
      isOpen={isFilterOpen}
      label={intl.formatMessage(filters['filter.beneficiary.page.title'])}
      closeFilter={() => setIsFilterOpen(false)}
      groups={[{ elements: filterOptions }]}
      filterState={filterState}
      setFilterState={handleSetFilterState}
      beneficiaryFilterField={true}
    />
  )

  const handleClearFilters = (): void => {
    handleFilterChange('')
  }

  const secondMenuGroup = [
    {
      align: 'center',
      id: 'second-group-filter',
      elements: [
        {
          label: intl.formatMessage(filters['filter.clear']),
          type: 'button' as const,
          icon: <CloseOutlined />,
          visibility: showClearFilter,
          callback: () => handleClearFilters(),
          dataTestId: 'clear-filter-btn',
        },
      ],
    },
  ]

  const secondaryMenu = (
    <SecondaryMenu label={intl.formatMessage(action['action.pi.page.second.action.title'])} groups={secondMenuGroup} />
  )

  const selectBeneficiary = (id: string): void => {
    const beneficiary =
      beneficiaries && beneficiaries.find((beneficiary: Beneficiary) => beneficiary && beneficiary.id === id)

    if (beneficiary) {
      setBeneficiary(beneficiary)
    }
  }

  const getPagination = (currentPage - 1) * PAGE_SIZE
  const params = {
    skip: getPagination,
  }

  const openDetails = (id: string): void => {
    selectBeneficiary(id)
    setDrawerHash(history, `#drawer-details?key=${id}`)
  }
  const openDelete = (id: string): void => {
    const beneficiary =
      beneficiaries && beneficiaries.find((beneficiary: Beneficiary) => beneficiary && beneficiary.id === id)

    if (beneficiary) {
      !isDeleteBeneficiaryWindowOpen ? setIsDeleteBeneficiaryWindowOpen(true) : setIsDeleteBeneficiaryWindowOpen(false)
      showDeleteConfirmationModal(beneficiary)
      resetDrawerHash(history)
    }
  }

  const handleCreateBeneficiary = async (data: Beneficiary, supplierFunded?: SupplierFunded): Promise<void> => {
    try {
      const response = await api.createBeneficiary(data)

      if (supplierFunded) {
        void updateSupplierFundedRegistry(supplierFunded, response.id!)
      }
      resetDrawerHash(history)
      if (beneficiariesFilter) {
        void searchBeneficiaries(beneficiariesFilter, params)
      } else {
        void getBeneficiaries(params)
      }
      notification.success({
        message: intl.formatMessage(messages['messages.success.beneficiary.add']),
        placement: 'topRight',
      })
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.beneficiary.add']),
        placement: 'topRight',
      })
    }
  }

  const updateSupplierFundedRegistry = async (supplierFunded: SupplierFunded, beneficiaryId: string): Promise<void> => {
    const { supplierFundedRegistry } = rules!.logic
    const supplierFundedIndex = supplierFundedRegistry.rule.findIndex(
      (data: SupplierFunded) => data.beneficiaryId === beneficiaryId
    )

    if (supplierFundedIndex !== -1) {
      // remove existing supplierFunded
      supplierFundedRegistry.rule.splice(supplierFundedIndex, 1)
    }

    const rule: SupplierFunded[] = [...supplierFundedRegistry.rule]

    // supplierFunded is empty object if it needs to be removed from the supplierFundedRegistry
    // add supplierFunded only if supplierFunded object has properties
    if (Object.keys(supplierFunded).length) {
      rule.push({
        beneficiaryId,
        ...supplierFunded,
      })
    }

    const body = {
      supplierFundedRegistry: {
        ...supplierFundedRegistry,
        rule,
      },
    }
    try {
      await rulesApi.updateEntityRules(body, entityId)
      const response = await rulesApi.getRules()
      setRules(response)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleDeleteBeneficiary = async (id: string): Promise<void> => {
    try {
      await api.deleteBeneficiary(id)
      setIsDeleteBeneficiaryWindowOpen(false)
      setSelectedRowsToDelete((selectedRows) => selectedRows.filter((row) => row !== id))
      if (beneficiariesFilter) {
        void searchBeneficiaries(beneficiariesFilter, params)
      } else {
        void getBeneficiaries(params)
      }
      notification.success({
        message: intl.formatMessage(messages['messages.success.beneficiary.delete'], { beneficiary: 1 }),
        placement: 'topRight',
      })
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.beneficiary.delete'], { beneficiary: 1 }),
        placement: 'topRight',
      })
    }
  }

  const handleDeleteMultipleBeneficiaries = async (beneficairyIds: Beneficiary['id'][]): Promise<void> => {
    try {
      const { success }: DeleteBeneficiariesResponse = await api.deleteMultipleBeneficiaries(beneficairyIds)
      setIsDeleteBeneficiaryWindowOpen(false)
      setSelectedRowsToDelete([])
      if (beneficiariesFilter) {
        void searchBeneficiaries(beneficiariesFilter, params)
      } else {
        void getBeneficiaries(params)
      }
      if (!success) {
        notification.warning({
          message: intl.formatMessage(messages['messages.partial.error.beneficiary.delete']),
          placement: 'topRight',
        })
      } else {
        notification.success({
          message: intl.formatMessage(messages['messages.success.beneficiary.delete'], {
            beneficiary: beneficairyIds.length,
          }),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.error({
        message: intl.formatMessage(messages['messages.error.beneficiary.delete'], {
          beneficiary: beneficairyIds.length,
        }),
        placement: 'topRight',
      })
    }
  }

  const showDeleteConfirmationModal = (beneficiary: Beneficiary | Beneficiary['id'][]) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: intl.formatMessage(messages['messages.prompt.deleteBeneficiaries'], {
        beneficiary: Array.isArray(beneficiary) ? beneficiary.length : 1,
      }),
      content: intl.formatMessage(messages['messages.prompt.delete'], {
        beneficiary: Array.isArray(beneficiary) ? beneficiary.length : 1,
      }),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () =>
        Array.isArray(beneficiary)
          ? handleDeleteMultipleBeneficiaries(beneficiary)
          : handleDeleteBeneficiary(beneficiary.id!),
      maskClosable: true,
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
        'data-testid': 'beneficiary-delete-confirm-btn',
      },
    })
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: unknown,
    sorter: SorterResult<Beneficiary> | SorterResult<Beneficiary>[]
  ): void => {
    const params: QueryParams = {}

    const { current, pageSize } = pagination
    const { field, order } = sorter as { field: string; order: string }

    if (current && pageSize) {
      const skip = (current - 1) * pageSize

      if (skip) {
        params.skip = skip
      }
    }

    if (field && order) {
      params.sortOrder = order === 'descend' ? 'desc' : 'asc'
      params.sortKey = Array.isArray(field) ? field.join('.') : field
    }

    if (beneficiariesFilter) {
      void searchBeneficiaries(beneficiariesFilter, params)
    } else void getBeneficiaries(params)
  }

  const mobileSearchFilter: MobileMenuOption[] = [
    {
      label: intl.formatMessage(filters['filter.search']),
      searchCallback: (value) => handleFilterChange(value),
      className: 'beneficiaryFilterField',
    },
  ]

  const mobileActionButtons: MobileMenuOption[] = [
    {
      id: 'placeholder',
      label: '',
      callback: () => null,
      className: 'd-none',
    },
    {
      id: 'add-card-btn',
      label: intl.formatMessage(action['action.beneficiary.add']),
      icon: <PlusOutlined />,
      callback: () => setDrawerHash(history, '#drawer-new-beneficiary'),
      type: 'primary',
    },
  ]

  const rowSelection: TableRowSelection<Beneficiary> = {
    onChange: (_: Key[], selectedRows: Beneficiary[]) => {
      setSelectedRowsToDelete(selectedRows.map((row) => row.id!))
    },
    type: 'checkbox',
  }

  return (
    <Page
      title={intl.formatMessage(page['page.beneficiaries.title'])}
      mobileMenuOptions={mobileActionButtons}
      mobileSearch={mobileSearchFilter}
      actionMenu={actionMenu}
      filter={filter}
      secondaryMenu={secondaryMenu}
    >
      <>
        {beneficiaries?.length || showLoader ? (
          <Loader showLoader={showLoader}>
            <Table
              rootClassName="bh-table"
              data-testid="beneficiaries-table"
              rowSelection={!isMobile && hasDeleteEnabled ? rowSelection : undefined}
              loading={showLoader}
              size="small"
              className={isMobile ? styles.beneficiaryTable : ''}
              dataSource={beneficiaries}
              columns={[...beneficiaryColumns(language, intl)]}
              showSorterTooltip={false}
              pagination={{
                total,
                showSizeChanger: false,
                position: isMobile ? ['bottomCenter'] : undefined,
                current: currentPage,
                onChange: setCurrentPage,
              }}
              rowClassName={isMobile ? '' : 'clickable'}
              onChange={handleTableChange}
              rowKey={(record: Beneficiary) => `beneficiary-key-${record.id!}`}
              onRow={(record: Beneficiary) => {
                return {
                  onClick: () => {
                    openDetails(record.id!)
                  },
                }
              }}
            />
          </Loader>
        ) : (
          <div className={styles.noContentMsg} data-testid="no-content-msg">
            {emptyMessage}
          </div>
        )}
        <ActionPage
          title={intl.formatMessage(beneficiaryStrings['beneficiary.slider.label.add'])}
          hash="#drawer-new-beneficiary"
          pathname={history.location.pathname}
          additionalClass="bh-action-page"
        >
          <RecipientsDrawer onSubmit={handleCreateBeneficiary} />
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(beneficiaryStrings['beneficiary.slider.label.details'])}
          additionalClass="bh-action-page"
          hash={`#drawer-details?key=${beneficiary.id!}`}
          pathname={history.location.pathname}
        >
          <NewBeneficiaryDetails beneficiary={beneficiary} />
          <div className={cx(styles.actionsContainer, styles.beneficiaryDetails)}>
            <ACL kind="beneficiary" barracksId={profileId} action="delete">
              <Button
                data-testid="beneficiary-delete-btn"
                icon={<DeleteOutlined />}
                type="primary"
                size="large"
                danger
                onClick={() => openDelete(beneficiary.id!)}
                block
              >
                {intl.formatMessage(beneficiaryStrings['beneficiary.delete.btn'])}
              </Button>
            </ACL>
          </div>
        </ActionPage>
      </>
    </Page>
  )
}

export default Beneficiaries
