import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { setDrawerHash } from 'components/Drawers/utils'
import { isDeviceType } from 'hooks'
import { AirplusIntegration } from 'types/rules'
import { getCardIcon } from 'utils/card'
import { DEVICE_TYPE } from 'utils/getDeviceType'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'utils/helpers'
import useActiveIntegration from 'hooks/useActiveIntegration'
import useCommonStyle from 'styles/common'

const VirtualCardsTable = () => {
  const { vcnIntegrations } = useActiveIntegration()
  const history = useHistory()
  const { styles: commonStyles } = useCommonStyle()

  const t = useTranslation()

  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)

  const columns: ColumnsType<AirplusIntegration> = [
    {
      title: t('card.virtualCard.label.account.provider'),
      dataIndex: 'provider',
      align: 'left',
      colSpan: 1,
      width: 50,
      key: 'icon',
      className: 'card-icons',
      render: () => {
        return <img src={getCardIcon('mc', 'vcn')} alt="card" width={30} />
      },
    },
    {
      title: t('card.virtualCard.label.account.name'),
      dataIndex: ['integrationProperties', 'accountName'],
    },
    {
      title: t('card.virtualCard.label.account.number'),
      dataIndex: ['integrationProperties', 'accountNumber'],
      render: (text: string) => `************ ${text.slice(-4)}`,
    },
    {
      title: t('card.virtualCard.label.account.currency'),
      dataIndex: ['integrationProperties', 'currency'],
      render: (text: string) => <span className={commonStyles.uppercaseString}>{text}</span>,
    },
  ]

  const openDetails = (id: string): void => {
    setDrawerHash(history, `#drawer-details-virtual-card?key=${id}`)
  }

  return (
    <div className={isMobile ? 'mobile-display' : 'desktop-display'}>
      <Table
        size="small"
        className={`bh-table ${isMobile ? 'mt-10' : ''}`}
        rowClassName="clickable bh-track-edit-card-list-item"
        dataSource={vcnIntegrations}
        data-testid="cards-table"
        columns={columns}
        showSorterTooltip={false}
        showHeader={!isMobile}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              openDetails(record.id)
            },
          }
        }}
      />
    </div>
  )
}

export default VirtualCardsTable
