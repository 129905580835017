import * as Sentry from '@sentry/react'
import { DetailedError } from 'api/utils'
import * as piApi from 'api/paymentInstruction'
import { getSets } from 'utils/init'
import { PAYMENT_STATE, Set, SetActionsTypes } from 'types/paymentInstruction'

async function createSetOrReuse(
  profileId: string,
  state: PAYMENT_STATE,
  piIds: string[] = [],
  templateIds: string[] = [],
  dispatchReadySet: (newSet: Set) => void,
  dispatchRequireSignatureSet: (newSet: Set) => void
): Promise<void> {
  const newSet: Set = {
    profileId,
    paymentInstructionState: state,
    additionalIdentifiers: [
      {
        key: 'action',
        value: SetActionsTypes.ADD,
      },
    ],
  }

  if (piIds.length) {
    newSet.paymentInstructionIds = [...piIds]
  }
  if (templateIds.length) {
    newSet.paymentInstructionTemplateIds = [...templateIds]
  }

  try {
    const response = await piApi.createSet(newSet)
    if (response.paymentInstructionState === PAYMENT_STATE.READY) {
      dispatchReadySet(response)
    } else {
      dispatchRequireSignatureSet(response)
    }
  } catch (error) {
    if (error instanceof DetailedError && error.details?.err?.status === 403) {
      try {
        const sets = await getSets(profileId)
        let existingSet: Set | undefined

        if (state === PAYMENT_STATE.READY) {
          existingSet = sets.readySet
        } else if (state === PAYMENT_STATE.REQUIRE_SIGNATURE) {
          existingSet = sets.requireSignatureSet
        }

        if (existingSet?.id) {
          if (piIds.length) {
            const updatedSet = await piApi.addPaymentInstructionToSet(existingSet.id, piIds)
            if (state === PAYMENT_STATE.READY) {
              dispatchReadySet(updatedSet)
            } else {
              dispatchRequireSignatureSet(updatedSet)
            }
          }
          if (templateIds.length) {
            const updatedSet = await piApi.addTemplateToSet(existingSet.id, templateIds)
            if (state === PAYMENT_STATE.READY) {
              dispatchReadySet(updatedSet)
            } else {
              dispatchRequireSignatureSet(updatedSet)
            }
          }
        } else {
          Sentry.captureMessage('createSet: 403 error received but no matching OPEN set found in getSets.')
        }
      } catch (fetchErr) {
        Sentry.captureException(fetchErr)
        throw fetchErr
      }
    } else {
      Sentry.captureException(error)
      throw error
    }
  }
}

export default createSetOrReuse
