import { defineMessages } from 'react-intl'

export const card = defineMessages({
  'card.form.cardNumber.label': {
    id: 'card.form.cardNumber.label',
    defaultMessage: 'Card number',
    description: 'Add card form field label',
  },
  'card.form.cardNumber.placeholder': {
    id: 'card.form.cardNumber.placeholder',
    defaultMessage: 'Enter card number',
    description: 'Add card form field placeholder',
  },
  'card.form.cardNumber.error.required': {
    id: 'card.form.cardNumber.error.required',
    defaultMessage: 'Please enter your card number',
    description: 'Add card form field requiered error',
  },
  'card.form.preferredCurrency.error.required': {
    id: 'card.form.preferredCurrency.error.required',
    defaultMessage: 'Please enter preferred currency',
    description: 'Add card form field requiered error',
  },
  'card.form.cardNumber.error.notSupported': {
    id: 'card.form.cardNumber.error.notSupported',
    defaultMessage: 'Card network {network} is not supported',
    description: 'Add card form field not suported error',
  },
  'card.form.cardNumber.error': {
    id: 'card.form.cardNumber.error',
    defaultMessage: 'Invalid card number',
    description: 'Add card form field invalid error',
  },
  'card.form.available.entities.error.required': {
    id: 'card.form.available.entities.error.required',
    defaultMessage: 'At least one entity must be selected',
    description: 'Add card form field not suported error',
  },
  'card.form.name.label': {
    id: 'card.form.name.label',
    defaultMessage: 'Your name',
    description: 'Add card form field label',
  },
  'card.form.invalid.type.of.api.call': {
    id: 'card.form.invalid.type.of.api.call',
    defaultMessage: 'Invalid type of api call',
    description: 'Error message for when we have an api call that is invalid',
  },
  'card.form.name.placeholder': {
    id: 'card.form.name.placeholder',
    defaultMessage: 'Enter your name',
    description: 'Add card form field placeholder',
  },
  'card.form.expiryMonth.label': {
    id: 'card.form.expiryMonth.label',
    defaultMessage: 'Expiry month',
    description: 'Add card form field label',
  },
  'card.form.expiryMonth.placeholder': {
    id: 'card.form.expiryMonth.placeholder',
    defaultMessage: 'Expiry month',
    description: 'Add card form field placeholder',
  },
  'card.form.expiryMonth.error.required': {
    id: 'card.form.expiryMonth.error.required',
    defaultMessage: 'Please select expiry month',
    description: 'Add card form field requiered error',
  },
  'card.form.expiryMonth.error': {
    id: 'card.form.expiryMonth.error',
    defaultMessage: 'The expiration month must be between 01 and 12',
    description: 'Add card form field validation error',
  },
  'card.form.expiryYear.label': {
    id: 'card.form.expiryYear.label',
    defaultMessage: 'Expiry year',
    description: 'Add card form field label',
  },
  'card.form.expiryYear.placeholder': {
    id: 'card.form.expiryYear.placeholder',
    defaultMessage: 'Expiry year',
    description: 'Add card form field placeholder',
  },
  'card.form.expiryYear.error.required': {
    id: 'card.form.expiryYear.error.required',
    defaultMessage: 'Please select expiry year',
    description: 'Add card form field requiered error',
  },
  'card.form.expiryYear.error': {
    id: 'card.form.expiryYear.error',
    defaultMessage: 'Expiration year cannot be in the past',
    description: 'Add card form field validation error',
  },
  'card.form.expiryDate.error.required': {
    id: 'card.form.expiryDate.error.required',
    defaultMessage: 'Please select expiry date',
    description: 'Add card form field requiered error',
  },
  'card.form.expiryDate.error': {
    id: 'card.form.expiryDate.error',
    defaultMessage: 'The expiration date is invalid',
    description: 'Add card form field validation error',
  },
  'card.form.expiryDate.label': {
    id: 'card.form.expiryDate.label',
    defaultMessage: 'Expiry date',
    description: 'Add card form field label',
  },
  'card.form.cvc.label': {
    id: 'card.form.cvc.label',
    defaultMessage: 'Security Code',
    description: 'Add card form field label',
  },
  'card.form.cvc.placeholder': {
    id: 'card.form.cvc.placeholder',
    defaultMessage: 'CVC/CVV',
    description: 'Add card form field placeholder',
  },
  'card.form.cvc.error.required': {
    id: 'card.form.cvc.error.required',
    defaultMessage: 'Please enter CVC/CVV',
    description: 'Add card form field required error',
  },
  'card.form.preferred.currency.error.required': {
    id: 'card.form.preferred.currency.error.required',
    defaultMessage: 'Please select currency',
    description: 'Add card form field required error',
  },
  'card.form.cvc.error': {
    id: 'card.form.cvc.error',
    defaultMessage: 'Invalid CVC/CVV',
    description: 'Add card form field validation error',
  },
  'card.form.cvc.button.label': {
    id: 'card.form.cvc.button.label',
    defaultMessage: 'What is this?',
    description: 'What is cvc button label',
  },
  'card.form.cardName.label': {
    id: 'card.form.cardName.label',
    defaultMessage: 'Name your card',
    description: 'Add card form field label',
  },
  'card.form.editCardName.label': {
    id: 'card.form.editCardName.label',
    defaultMessage: 'Card name',
    description: 'Edit card form field label',
  },
  'card.form.cardName.placeholder': {
    id: 'card.form.cardName.placeholder',
    defaultMessage: 'Enter card name',
    description: 'Add card form field placeholder',
  },
  'card.form.cardName.error.required': {
    id: 'card.form.cardName.error.required',
    defaultMessage: 'Please enter a name for your card',
    description: 'Add card form field requiered error',
  },
  'card.form.submitButton.label': {
    id: 'card.form.submitButton.label',
    defaultMessage: 'Add',
    description: 'Add card form submit button label',
  },
  'card.form.updateButton.label': {
    id: 'card.form.updateButton.label',
    defaultMessage: 'Update',
    description: 'Edit card form submit button label',
  },
  'card.slider.label.add': {
    id: 'card.slider.label.add',
    defaultMessage: 'Add Source',
    description: 'Right slider label for adding new card',
  },
  'card.slider.label.details': {
    id: 'card.slider.label.details',
    defaultMessage: 'Source details',
    description: 'Right slider label for details of a card',
  },
  'card.cardName': {
    id: 'card.cardName',
    defaultMessage: 'Card name',
    description: 'Card name label',
  },
  'card.number': {
    id: 'card.number',
    defaultMessage: 'Number',
    description: 'Card number label',
  },
  'card.expiryDate': {
    id: 'card.expiryDate',
    defaultMessage: 'Expiry date',
    description: 'Card expiry date label',
  },
  'card.details': {
    id: 'card.details',
    defaultMessage: 'Details',
    description: 'Card detailslabel',
  },
  'card.type': {
    id: 'card.type',
    defaultMessage: 'Type',
    description: 'Card type label',
  },
  'card.cardNetwork': {
    id: 'card.cardNetwork',
    defaultMessage: 'Card network',
    description: 'Card card network label',
  },
  'card.card.number': {
    id: 'card.card.number',
    defaultMessage: 'Card number',
    description: 'Card number label',
  },
  'card.bin': {
    id: 'card.bin',
    defaultMessage: 'Bin',
    description: 'Card bin label',
  },
  'card.last4': {
    id: 'card.last4',
    defaultMessage: 'Last 4',
    description: 'Card last 4 label',
  },
  'card.gatewayAccount': {
    id: 'card.gatewayAccount',
    defaultMessage: 'Gateway account',
    description: 'Card gateway account label',
  },
  'card.preferredCurrency': {
    id: 'card.preferredCurrency',
    defaultMessage: 'Preferred currency',
    description: 'Card preferred currencies label',
  },
  'card.preferredCurrency.placeholder': {
    id: 'card.preferredCurrency.placeholder',
    defaultMessage: 'Please select a currency',
    description: 'Card preferred currencies placeholder',
  },
  'card.availableEntities': {
    id: 'card.availableEntities',
    defaultMessage: 'Available to entities',
    description: 'Available entities for a card label',
  },
  'card.availableEntities.placeholder': {
    id: 'card.availableEntities.placeholder',
    defaultMessage: 'Select entities',
    description: 'Available entities for a card placeholder',
  },
  'card.availableProfileGroups': {
    id: 'card.availableProfileGroups',
    defaultMessage: 'Available to profile groups',
    description: 'Available profile groups for a card label',
  },
  'card.availableProfileGroups.placeholder': {
    id: 'card.availableProfileGroups.placeholder',
    defaultMessage: 'Select profile groups',
    description: 'Available profile groups for a card placeholder',
  },
  'card.form.submitError.GENERAL_ERROR': {
    id: 'card.form.submitError.GENERAL_ERROR',
    defaultMessage: 'GENERAL_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.INSUFFICIENT_FUNDS': {
    id: 'card.form.submitError.INSUFFICIENT_FUNDS',
    defaultMessage: 'INSUFFICIENT_FUNDS',
    description: 'Card form submit error message',
  },
  'card.form.submitError.EXCEEDS_WITHDRAWAL_LIMIT': {
    id: 'card.form.submitError.EXCEEDS_WITHDRAWAL_LIMIT',
    defaultMessage: 'EXCEEDS_WITHDRAWAL_LIMIT',
    description: 'Card form submit error message',
  },
  'card.form.submitError.FORMAT_ERROR': {
    id: 'card.form.submitError.FORMAT_ERROR',
    defaultMessage: 'FORMAT_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.PAN_FORMAT_ERROR': {
    id: 'card.form.submitError.PAN_FORMAT_ERROR',
    defaultMessage: 'PAN_FORMAT_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.DATE_FORMAT_ERROR': {
    id: 'card.form.submitError.DATE_FORMAT_ERROR',
    defaultMessage: 'DATE_FORMAT_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.EXPIRED': {
    id: 'card.form.submitError.EXPIRED',
    defaultMessage: 'EXPIRED',
    description: 'Card form submit error message',
  },
  'card.form.submitError.CVV_ERROR': {
    id: 'card.form.submitError.CVV_ERROR',
    defaultMessage: 'CVV_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.CVC_FORMAT_ERROR': {
    id: 'card.form.submitError.CVC_FORMAT_ERROR',
    defaultMessage: 'CVC_FORMAT_ERROR',
    description: 'Card form submit error message',
  },
  'card.form.submitError.BANK_REFUSAL': {
    id: 'card.form.submitError.BANK_REFUSAL',
    defaultMessage: 'BANK_REFUSAL',
    description: 'Card form submit error message',
  },
  'card.form.submitError.SCA_FAIL': {
    id: 'card.form.submitError.SCA_FAIL',
    defaultMessage: 'SCA_FAIL',
    description: 'Card form submit error message',
  },
  'card.form.submitError.SCA_EXPIRED': {
    id: 'card.form.submitError.SCA_EXPIRED',
    defaultMessage: 'SCA_EXPIRED',
    description: 'Card form submit error message',
  },
  'card.form.submitError.BIN_ERROR': {
    id: 'card.form.submitError.BIN_ERROR',
    defaultMessage: 'Bin number is not valid',
    description: 'Card form submit error message',
  },
  'card.not.added': {
    id: 'card.not.added',
    defaultMessage: 'No cards added',
    description: 'No cards added message',
  },
  'card.not.found': {
    id: 'card.not.found',
    defaultMessage: 'No cards found',
    description: 'No cards found message',
  },
  'card.modal.warning': {
    id: 'card.modal.warning',
    defaultMessage: 'There are some problems when adding your card',
    description: 'Warning text for card added',
  },
  'card.modal.errorCodeInfo': {
    id: 'card.modal.errorCodeInfo',
    defaultMessage: 'The error code we got from the card issuer was: {errorCode}',
    description: 'Warning text for card added',
  },
  'card.modal.success': {
    id: 'card.modal.success',
    defaultMessage: 'Your card was added successfully',
    description: 'Success text for card added',
  },
  'card.modal.btn.title': {
    id: 'card.modal.btn.title',
    defaultMessage: 'Ok',
    description: 'Button text for the popup/modal',
  },
  'card.modal.btn.fail.title': {
    id: 'card.modal.btn.fail.title',
    defaultMessage: 'Ok',
    description: 'Button text for the popup/modal when something failed',
  },
  'card.form.delete': {
    id: 'card.form.delete',
    defaultMessage: 'Delete',
    description: 'Edit card form delete',
  },
  'card.form.submitError.BIN_BLOCKED': {
    id: 'card.form.submitError.BIN_BLOCKED',
    defaultMessage: 'BIN BLOCKED',
    description: 'Card form submit error message',
  },
  'card.form.cardId.label': {
    id: 'card.form.cardId.label',
    defaultMessage: 'Card ID',
    description: 'Add card form field label for Card ID',
  },
  'card.copySuccess': {
    id: 'card.copySuccess',
    defaultMessage: 'Card ID copied successfully!',
    description: 'Message shown when a card ID is successfully copied',
  },
  'card.virtualCard.label.account.provider': {
    id: 'card.virtualCard.label.account.provider',
    defaultMessage: 'Provider',
    description: 'Virtual card provider label',
  },
  'card.virtualCard.label.account.name': {
    id: 'card.virtualCard.label.account.name',
    defaultMessage: 'Name of account',
    description: 'Virtual card name label',
  },
  'card.virtualCard.label.account.number': {
    id: 'card.virtualCard.label.account.number',
    defaultMessage: 'Account number',
    description: 'Virtual card number label',
  },
  'card.virtualCard.label.account.currency': {
    id: 'card.virtualCard.label.account.currency',
    defaultMessage: 'Card billing currency',
    description: 'Virtual card currency label',
  },
  'card.virtualCard.label.account.provider.fields': {
    id: 'card.virtualCard.label.account.provider.fields',
    defaultMessage: 'VCN Provider Fields',
    description: 'Virtual card VCN Provider Fields',
  },
  'card.virtualCard.airplus': {
    id: 'card.virtualCard.airplus',
    defaultMessage: 'Airplus Virtual Card',
    description: 'Airplus Virtual Card label',
  },
  'card.virtualCard.details.title': {
    id: 'card.virtualCard.details.title',
    defaultMessage: 'Virtual Cards (VCN) Details',
    description: 'Virtual Card details title',
  },
  'card.virtualCards': {
    id: 'card.virtualCards',
    defaultMessage: 'Virtual Cards',
    description: 'Virtual Card tab',
  },
  'card.standardCards': {
    id: 'card.standardCards',
    defaultMessage: 'Standard Cards',
    description: 'Standard Card tab',
  },
  'card.messages.error.source.edit': {
    id: 'card.messages.error.source.edit',
    defaultMessage: 'Error editing source',
    description: 'Error message for editing source',
  },
  'card.messages.error.dbis': {
    id: 'card.messages.error.dbis',
    defaultMessage: 'Error fetching dbis',
    description: 'Error message for fetching dbis',
  },
})
