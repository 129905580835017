import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from 'antd'

const { Text } = Typography

interface Props {
  countryCode: string
  height: number
}

const style = {
  paddingLeft: 11,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}

const PayoutCountryFlag = (props: Props): React.JSX.Element => {
  const { countryCode, height } = props
  const intl = useIntl()

  return (
    <div style={style}>
      <img
        style={{ height }}
        src={`https://flagcdn.com/84x63/${countryCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/168x126/${countryCode.toLowerCase()}.png 2x, https://flagcdn.com/252x189/${countryCode.toLowerCase()}.png 3x`}
        alt={countryCode.toLowerCase()}
      />
      <Text style={{ fontSize: 14 }}>
        {intl.formatDisplayName(countryCode.toUpperCase(), {
          type: 'region',
        })}
      </Text>
    </div>
  )
}

export default PayoutCountryFlag
