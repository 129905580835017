import { Button, Collapse, Form, Input, notification, Space } from 'antd'
import { getDbis } from 'api/cardio'
import { VcnProvider } from 'types/cardio'
import { AirplusIntegration } from 'types/rules'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'utils/helpers'
import { editActiveIntegration, getRules } from 'api/rules'
import { useActiveUser } from 'hooks'
import { useHistory, useLocation } from 'react-router-dom'
import useActiveIntegration from 'hooks/useActiveIntegration'
import ActionPage from 'components/ActionPage/ActionPage'
import { useSession } from 'stores/session'
import useCommonStyle from 'styles/common'
import AlertMessagesContainer from 'components/AlertMessage/AlertMessagesContainer'
import { notifyError } from 'utils/error'
import { resetDrawerHash } from 'components/Drawers/utils'

interface VcnField {
  name: string
  translationKey?: string
}

const VirtualCardDetails = () => {
  const [vcnDbis, setVcnDbis] = useState<VcnField[]>()
  const { styles: commonStyles } = useCommonStyle()

  const {
    actions: { setRules },
  } = useSession()
  const { vcnIntegrations } = useActiveIntegration()
  const [form] = Form.useForm()
  const { profile } = useActiveUser()
  const t = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const id = location.hash.split('=')?.[1]
  const isEntityAdmin = profile.entityRoles.includes('admin')
  const integration = vcnIntegrations.find((vcnIntegration) => vcnIntegration.id === id)

  useEffect(() => {
    if (!id) return
    void fetchAndMapVcnFields()
  }, [id])

  const fetchAndMapVcnFields = async () => {
    try {
      const dbis = await getDbis(VcnProvider.AIRPLUS)
      const mappedFields: VcnField[] = Object.keys(integration?.integrationProperties.dbis || []).map((key) => {
        const dbi = dbis.find((dbi) => dbi.name === key)
        return {
          name: key || '',
          translationKey: dbi?.translationKey,
        }
      })
      setVcnDbis(mappedFields)
    } catch (error) {
      notifyError(t('card.messages.error.dbis'))
      Sentry.captureException(error)
    }
  }

  const onSubmit = async (values: AirplusIntegration) => {
    try {
      await editActiveIntegration(id, values.integrationProperties.accountName)
      const rules = await getRules()
      setRules(rules)
      notification.success({
        message: t('messages.success.source.edit'),
        placement: 'topRight',
      })
      resetDrawerHash(history)
    } catch (error) {
      notifyError(t('card.messages.error.source.edit'))
      Sentry.captureException(error)
    }
  }

  return (
    <ActionPage
      title={t('card.virtualCard.details.title')}
      hash={`#drawer-details-virtual-card?key=${id}`}
      pathname={location.pathname}
      additionalClass="bh-action-page"
    >
      <Space direction="vertical" size="middle">
        {isEntityAdmin && <AlertMessagesContainer placement="virtualCardDetails" />}
        <Form
          onFinish={(values: AirplusIntegration) => void onSubmit(values)}
          form={form}
          initialValues={integration}
          layout="vertical"
        >
          <Form.Item name="providerId" label={t('card.virtualCard.label.account.provider')}>
            <Input disabled className={commonStyles.capitalizeFirstLetter} />
          </Form.Item>
          <Form.Item name={['integrationProperties', 'accountName']} label={t('card.virtualCard.label.account.name')}>
            <Input disabled={!isEntityAdmin} />
          </Form.Item>
          <Form.Item
            name={['integrationProperties', 'accountNumber']}
            label={t('card.virtualCard.label.account.number')}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item name={['integrationProperties', 'currency']} label={t('card.virtualCard.label.account.currency')}>
            <Input disabled className={commonStyles.uppercaseString} />
          </Form.Item>
          <Form.Item>
            <Collapse
              key="1"
              defaultActiveKey={['1']}
              items={[
                {
                  key: '1',
                  label: t('card.virtualCard.label.account.provider.fields'),
                  children: (
                    <>
                      {vcnDbis?.map(({ name, translationKey }) => (
                        <div key={translationKey}>
                          ({name}) {translationKey && t(translationKey)}
                        </div>
                      ))}
                    </>
                  ),
                },
              ]}
            />
          </Form.Item>
          {isEntityAdmin && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('card.form.updateButton.label')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Space>
    </ActionPage>
  )
}
export default VirtualCardDetails
