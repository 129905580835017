import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Row, Col, Radio } from 'antd'
import { Set } from 'types/paymentInstruction'
import { Approver } from 'types/user'
import { pi } from 'lang/definitions'
import Loader from 'components/Loader/Loader'
import { useCurrentPayment } from 'stores/Payment'
import { useSession } from 'stores/session'
import useApproversStyle from './Approvers.style'
import { fetchPIsAndTemplatesForSignatureRequest } from 'pages/PaymentInstructions/utils'

interface ApproversProps {
  set?: Set
  approvers: Approver[] | undefined
  currentRound: number
  getNumberOfSignaturesRequired: (paymentIds: string[]) => void
}

const Approvers = (props: ApproversProps): React.JSX.Element => {
  const { set, approvers, currentRound, getNumberOfSignaturesRequired } = props

  const { styles } = useApproversStyle()
  const intl = useIntl()
  const {
    state: { rules },
  } = useSession()

  const selectAllApproversParallelFlow = rules?.toggles?.selectAllApproversParallelFlow?.rule

  const [numberOfPayments, setNumberOfPayments] = useState<number>(0)

  const {
    state: { selectedApprovers, selectedPayments, numberOfSignaturesRequired, signatureOrder },
    actions: { setSelectedApprovers },
  } = useCurrentPayment()

  useEffect(() => {
    const fetchPIsAndTemplatesToApprove = async (set: Set) => {
      const includeSignatures: boolean = currentRound !== null && currentRound === 0
      const result = await fetchPIsAndTemplatesForSignatureRequest(set, !!includeSignatures)

      const { paymentInstructions, paymentInstructionTemplates } = result
      const filteredPIIds: string[] =
        paymentInstructions?.map((pi) => pi.id).filter((id): id is string => typeof id === 'string') || []
      const filteredTemplateIds: string[] =
        paymentInstructionTemplates
          ?.map((template) => template.id)
          .filter((id): id is string => typeof id === 'string') || []
      const payments = [...filteredPIIds, ...filteredTemplateIds]
      getNumberOfSignaturesRequired(payments)
    }

    if (!set) {
      return
    }
    const numberOfPayments =
      (set?.paymentInstructionIds?.length || 0) + (set?.paymentInstructionTemplateIds?.length || 0)

    setNumberOfPayments(numberOfPayments)
    void fetchPIsAndTemplatesToApprove(set)
  }, [set, selectedPayments, currentRound])

  useEffect(() => {
    signatureOrder === 'parallel' && selectAllApproversParallelFlow && selectAllApprovers()
  }, [approvers])

  const selectApprovers = (checkedValue: string[]): void => {
    if (!approvers) {
      return
    }

    const selectedApprovers = approvers.filter((approver: Approver) => checkedValue.includes(approver.id))
    setSelectedApprovers(selectedApprovers)
  }

  const selectAllApprovers = (): void => {
    if (approvers) setSelectedApprovers([...approvers])
  }

  const deselectAllApprovers = (): void => {
    setSelectedApprovers([])
  }

  const isAllApproversButtonActive = approvers ? approvers.length === selectedApprovers.length : null

  return (
    <React.Fragment>
      {!!numberOfPayments && (
        <div className={styles.container} data-testid="Approvers-component">
          <div className={styles.approversLabel}>
            <span>{intl.formatMessage(pi['pi.add.reviewPayments.approvers.header'], { numberOfPayments })}</span>
          </div>
          <div className={styles.approversText}>
            <span>
              {signatureOrder === 'parallel'
                ? intl.formatMessage(pi['pi.add.reviewPayments.approvers.message'], {
                    numberOfSignaturesRequired,
                  })
                : intl.formatMessage(pi['pi.add.reviewPayments.approvers.sequential.message'], {
                    numberOfSignaturesRequired,
                    round: currentRound,
                  })}
            </span>
          </div>
          {approvers ? (
            <div className={styles.approversList} data-testid="approvers-list">
              <div className={styles.approversListHeader}>
                <div className={styles.label}>
                  <span>
                    {signatureOrder === 'parallel'
                      ? intl.formatMessage(pi['pi.add.reviewPayments.required.approvers'])
                      : intl.formatMessage(pi['pi.add.reviewPayments.approvers.available.approvers'])}
                  </span>
                  {signatureOrder === 'parallel' && (
                    <span>{`(${selectedApprovers.length}/${numberOfSignaturesRequired})`}</span>
                  )}
                </div>
                <div className="all-users-select-options">
                  <Radio.Group size="small">
                    <Radio.Button
                      className={isAllApproversButtonActive ? styles.primary : 'default'}
                      onClick={selectAllApprovers}
                    >
                      {intl.formatMessage(pi['pi.add.reviewPayments.approvers.list.selectOption.all'])}
                    </Radio.Button>
                    <Radio.Button onClick={deselectAllApprovers}>
                      {intl.formatMessage(pi['pi.add.reviewPayments.approvers.list.selectOption.none'])}
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={selectedApprovers.map((value: Partial<Approver>) => value.id) as string[]}
                onChange={(checkedValue: string[]) => selectApprovers(checkedValue)}
              >
                <Row>
                  {approvers.map(
                    (approver: Approver): React.ReactNode => (
                      <Col span={6} key={`approver-${approver.id}`}>
                        <Checkbox value={approver.id}>{`${approver.name.first} ${approver.name.last}`}</Checkbox>
                      </Col>
                    )
                  )}
                </Row>
              </Checkbox.Group>
            </div>
          ) : (
            <div style={{ textAlign: 'center', paddingTop: 10 }}>
              <Loader showLoader={true} />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default Approvers
