import { Flex, Input, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import { isDeviceType } from 'hooks'
import { Source } from 'types/source'
import { DEVICE_TYPE } from 'utils/getDeviceType'
import { useTranslation } from 'utils/helpers'

interface CardsTableProps {
  sources: Source[]
  columns: ColumnsType<Source>
  openDetails: (id: string) => void
  showLoader: boolean
  rowSelection: TableRowSelection<Source>
  emptyMessage: string
  paginationProps?: {
    total: number | undefined
    current: number
    onChange: React.Dispatch<React.SetStateAction<number>>
  }
  hasDeleteEnabled?: boolean
  secondaryMenu: React.ReactNode
  filter: React.ReactNode
  handleFilterChange: (value: string) => void
}

const CardsTable = ({
  sources,
  columns,
  openDetails,
  showLoader,
  emptyMessage,
  paginationProps,
  hasDeleteEnabled,
  rowSelection,
  filter,
  secondaryMenu,
  handleFilterChange,
}: CardsTableProps) => {
  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)
  const t = useTranslation()

  return (
    <>
      {isMobile ? (
        <Input.Search
          placeholder={t('filter.search.placeholder')}
          onSearch={(value) => handleFilterChange(value)}
          allowClear={true}
        />
      ) : (
        <Flex vertical={false}>
          {filter}
          {secondaryMenu}
        </Flex>
      )}
      <div className={isMobile ? 'mobile-display' : 'desktop-display'}>
        <Table
          rowSelection={!isMobile && hasDeleteEnabled ? rowSelection : undefined}
          size="small"
          className={`bh-table ${isMobile ? 'mt-10' : ''}`}
          rowClassName="clickable bh-track-edit-card-list-item"
          dataSource={sources}
          data-testid="cards-table"
          loading={showLoader}
          locale={{
            emptyText: emptyMessage,
          }}
          columns={columns}
          showSorterTooltip={false}
          pagination={{ ...paginationProps, position: isMobile ? ['bottomCenter'] : undefined }}
          showHeader={!isMobile}
          rowKey={(record) => `${record.id}`}
          onRow={(record) => {
            return {
              onClick: () => {
                openDetails(record.id)
              },
            }
          }}
        />
      </div>
    </>
  )
}

export default CardsTable
