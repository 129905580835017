import React, { useState, useEffect } from 'react'
import { Descriptions, Button, Collapse, message, Avatar } from 'antd'
import * as Sentry from '@sentry/react'
import { Attachment, PAYMENT_STATE, PaymentInstruction } from 'types/paymentInstruction'
import { useLanguageState } from 'stores/language/LanguageStore'
import Date from 'components/Format/Date/Date'
import Currency from 'components/Format/Currency/Currency'
import { useIntl } from 'react-intl'
import { pi, action } from 'lang/definitions/index'
import AccountNumber from 'components/Format/AccountNumber/AccountNumber'
import { Approver } from 'types/user'
import { getProfileDetails } from 'api/user'
import { Profile } from 'types/profile'
import Loader from 'components/Loader/Loader'
import ACL from 'components/ACL/ACL'
import { getAttachment, getPaymentInstruction } from 'api/paymentInstruction'
import { downloadSinglePaymentInstructionPDF } from 'api/pdf'
import { FilePdfOutlined, RedoOutlined } from '@ant-design/icons'
import { getEntityName, useTranslation } from 'utils/helpers'
import { getInitials, stringToColor } from 'components/BHAvatar/utils'
import PaymentState from './components/PaymentState/PaymentState'
import usePaymentDetailsStyle from './PaymentInstructionDetails.style'
import { cx } from 'antd-style'
import DbiDescriptions from 'components/DbiDescriptions/DbiDescriptions'

interface PaymentInstructionDetailsProps {
  paymentInstructionId: string
  handleRemovePayment?: (id: string) => void
}

const PaymentInstructionDetails = ({
  paymentInstructionId,
  handleRemovePayment,
}: PaymentInstructionDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const t = useTranslation()
  const language = languageState.language
  const intl = useIntl()
  const { styles } = usePaymentDetailsStyle()

  const [profile, setProfile] = useState<Profile>()
  const [payment, setPayment] = useState<PaymentInstruction>()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false)
  const [remittanceDownLoader, setRemittanceDownLoader] = useState<boolean>(false)
  const [externalReceiptLoader, setExternalReceiptLoader] = useState<boolean>(false)

  useEffect(() => {
    if (paymentInstructionId) {
      void getData(paymentInstructionId)
    }
  }, [paymentInstructionId])

  const getData = async (paymentInstructionId: string) => {
    try {
      setShowLoader(true)
      const payment = await getPaymentInstruction(paymentInstructionId)
      setPayment(payment)
      if (payment) {
        const { profile } = await getProfileDetails(payment.profileId)
        setProfile(profile)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleDownloadReceipts = async (reportType: string): Promise<void> => {
    const classType = Object.keys(profile?.entity?.class || [])

    if (reportType === 'remittance-advice') {
      setRemittanceDownLoader(true)
    } else if (reportType === 'internal-receipt') {
      setDownloadLoader(true)
    } else {
      setExternalReceiptLoader(true)
    }

    try {
      const key = 'updatable'
      void message.loading({
        content: intl.formatMessage(action['action.pi.page.download.progress']),
        key,
      })

      const response = await downloadSinglePaymentInstructionPDF(paymentInstructionId, reportType, classType[0])

      void message.success({
        content: intl.formatMessage(action['action.pi.page.download.finished']),
        key,
        duration: 2,
      })

      let fileName = `billhop-receipt-${paymentInstructionId}`
      if (reportType === 'remittance-advice') {
        fileName = `billhop-remittance-advice-${paymentInstructionId}`
      }

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName}.pdf`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setDownloadLoader(false)
      setRemittanceDownLoader(false)
      setExternalReceiptLoader(false)
    }
  }

  const handleDownloadAttachment = async (piId: string, attachmentId: string): Promise<void> => {
    try {
      const response = await getAttachment(piId, attachmentId)

      const fileName = payment?.attachments![0].filename

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName!}`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const refundButtonLabel =
    payment && payment.state === PAYMENT_STATE.PENDING
      ? intl.formatMessage(action['action.pi.refund'])
      : intl.formatMessage(action['action.pi.delete'])

  return (
    <Loader showLoader={showLoader}>
      {payment && (
        <div className={cx(styles.dropdownFilterWrapper)} data-testid="payment-instruction-wrapper">
          <Collapse
            items={[
              {
                key: '1',
                label: t('pi.details.payment'),
                children: (
                  <Descriptions size="small" className={styles.noBorder} bordered>
                    {profile && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.details.createdBy'])} span={4}>
                        <Avatar
                          style={{
                            backgroundColor: stringToColor(`${profile.user.name.first} ${profile.user.name.last}`),
                            verticalAlign: 'middle',
                            color: 'black',
                          }}
                          size="small"
                        >
                          {' ' + getInitials(`${profile.user.name.first} ${profile.user.name.last}`)}
                        </Avatar>
                        {`${profile.user.name.first} ${profile.user.name.last}`}
                      </Descriptions.Item>
                    )}
                    {payment.state && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.status'])} span={3}>
                        <PaymentState state={payment.state} />
                      </Descriptions.Item>
                    )}
                    {profile && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.entity'])} span={3}>
                        {getEntityName(profile)}
                      </Descriptions.Item>
                    )}
                    {payment.dateDue && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.dateDue'])} span={3}>
                        <Date value={payment.dateDue} locale={language} />
                      </Descriptions.Item>
                    )}
                    {payment.amount && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.amount'])} span={3}>
                        <Currency value={payment.amount.toString()} locale={language} currency={payment.currency} />
                      </Descriptions.Item>
                    )}
                    {payment.accounting?.feeFixed && (
                      <Descriptions.Item
                        label={intl.formatMessage(pi['pi.feeFixed'])}
                        span={3}
                        style={{ color: 'rgba(0,0,0,0.60' }}
                      >
                        <Currency
                          value={payment.accounting?.feeFixed.toString()}
                          locale={language}
                          currency={payment.currency}
                          elementType="span"
                        />
                        {payment.accounting.feeFixedCurrency !== payment.currency && (
                          <span style={{ paddingLeft: '5px' }}>
                            (
                            <Currency
                              value={payment.accounting?.feeFixedAmountOriginalCurrency.toString()}
                              locale={language}
                              currency={payment.accounting.feeFixedCurrency}
                              elementType="span"
                            />
                            )
                          </span>
                        )}
                      </Descriptions.Item>
                    )}
                    {payment.accounting?.feeFixed && (
                      <Descriptions.Item
                        label={intl.formatMessage(pi['pi.feePercent'])}
                        span={3}
                        style={{ color: 'rgba(0,0,0,0.60' }}
                      >
                        <Currency
                          value={payment.accounting?.feePercentAmount.toString()}
                          locale={language}
                          currency={payment.currency}
                          elementType="span"
                        />
                        <span style={{ paddingLeft: '5px' }}>({payment.accounting?.feePercent * 100 + '%'})</span>
                      </Descriptions.Item>
                    )}
                    {payment.fee && (
                      <Descriptions.Item
                        label={
                          payment.accounting?.feeFixed
                            ? intl.formatMessage(pi['pi.feeTotal'])
                            : intl.formatMessage(pi['pi.fee'])
                        }
                        span={3}
                      >
                        <Currency value={payment.fee.toString()} locale={language} currency={payment.currency} />
                      </Descriptions.Item>
                    )}
                    {payment.amount && payment.fee && (
                      <Descriptions.Item
                        label={intl.formatMessage(pi['pi.total'])}
                        span={3}
                        style={{ fontWeight: 700 }}
                      >
                        <Currency
                          value={`${payment.fee + payment.amount}`.toString()}
                          locale={language}
                          currency={payment.currency}
                        />
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                ),
              },
            ]}
            expandIconPosition="end"
            defaultActiveKey={[1]}
          />
          {Array.isArray(payment?.source?.typeProperties?.vcnProviderProperties) &&
            payment.source.typeProperties.vcnProviderProperties.length > 0 && (
              <Collapse
                items={[
                  {
                    key: '1',
                    label: t('pi.vcnProviderProperties'),
                    children: (
                      <DbiDescriptions vcnProviderProperties={payment.source.typeProperties.vcnProviderProperties} />
                    ),
                  },
                ]}
                expandIconPosition="end"
                defaultActiveKey={[1]}
              />
            )}
          <Collapse
            items={[
              {
                key: '1',
                label: t('pi.details.recipient'),
                children: (
                  <Descriptions size="small" className={styles.noBorder} bordered>
                    {payment.beneficiary!.title && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.title'])} span={3}>
                        {payment.beneficiary!.title}
                      </Descriptions.Item>
                    )}
                    {payment.beneficiary!.accountNumber && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.accountNumber'])} span={3}>
                        <AccountNumber
                          accountNumber={payment.beneficiary!.accountNumber}
                          routingNumber={payment.beneficiary!.routingNumber}
                          clearingNetwork={payment.beneficiary!.clearingNetwork}
                        ></AccountNumber>
                      </Descriptions.Item>
                    )}
                    {payment.reference && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.reference'])} span={3}>
                        {payment.reference}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                ),
              },
            ]}
            expandIconPosition="end"
            defaultActiveKey={[1]}
          />
          {payment.signatures && (
            <Collapse
              items={[
                {
                  key: '1',
                  label: t('pi.details.signatures'),
                  children: (
                    <Descriptions size="small" className={styles.noBorder} bordered>
                      <Descriptions.Item label={intl.formatMessage(pi['pi.details.requireSignatureBy'])} span={3}>
                        {payment.signatures.signatureRequests.map((approversRound: Approver[], index: number) => (
                          <div className={styles.approverRounds} key={index}>
                            {payment.signatures!.signatureOrder === 'sequential' && (
                              <span className={styles.roundLabel}>
                                {intl.formatMessage(pi['pi.details.round'])}
                                &nbsp;
                                {index + 1}: &nbsp;
                              </span>
                            )}
                            <div>
                              {approversRound.map((approver: Approver) => (
                                <div
                                  key={`approver-${approver.userId}`}
                                >{`${approver.name.first} ${approver.name.last}`}</div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </Descriptions.Item>
                      <Descriptions.Item label={intl.formatMessage(pi['pi.details.signedBy'])} span={3}>
                        {payment.signatures.signedBy.map((approversRound: Approver[], index: number) => (
                          <div className={styles.approverRounds} key={index}>
                            {payment.signatures!.signatureOrder === 'sequential' && (
                              <span className={styles.roundLabel}>
                                {intl.formatMessage(pi['pi.details.round'])}
                                &nbsp;
                                {index + 1}: &nbsp;
                              </span>
                            )}
                            <div>
                              {approversRound.map((approver: Approver) => (
                                <div
                                  key={`approver-${approver.userId}`}
                                >{`${approver.name.first} ${approver.name.last}`}</div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
              expandIconPosition="end"
              defaultActiveKey={[1]}
            />
          )}
          <Collapse
            items={[
              {
                key: '1',
                label: t('pi.details.paymentCards'),
                children: (
                  <Descriptions size="small" className={styles.noBorder} bordered>
                    {payment.source?.title && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardTitle'])} span={3}>
                        {payment.source.title}
                      </Descriptions.Item>
                    )}
                    {payment.source && payment.source.typeProperties.bin && payment.source.typeProperties.last4 && (
                      <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardNubmer'])} span={3}>
                        {`${payment.source.typeProperties.bin} •••• •••• ${payment.source.typeProperties.last4}`}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                ),
              },
            ]}
            expandIconPosition="end"
            defaultActiveKey={[1]}
          />
          {payment.attachments && (
            <Collapse
              items={[
                {
                  key: '1',
                  label: t('pi.details.attachment'),
                  children: (
                    <div className={styles.attachments}>
                      {payment.attachments.map((attachment: Attachment) => (
                        <div key={`attachment-${attachment.attachmentId!}`}>
                          <Button
                            data-testid="check-invoice"
                            type="link"
                            onClick={() =>
                              void handleDownloadAttachment(paymentInstructionId, attachment.attachmentId!)
                            }
                          >
                            {attachment.filename}
                          </Button>
                          {attachment.comment && <div className={styles.attachmentComment}>{attachment.comment}</div>}
                        </div>
                      ))}
                    </div>
                  ),
                },
              ]}
              expandIconPosition="end"
              defaultActiveKey={[1]}
            />
          )}
          <Collapse
            items={[
              {
                key: '1',
                label: t('pi.actions'),
                children: (
                  <Descriptions
                    data-testid="actions-panel"
                    size="small"
                    className={cx(styles.noBorder, styles.actionPanel)}
                  >
                    <Descriptions.Item span={3}>
                      <Button
                        icon={<FilePdfOutlined />}
                        type="link"
                        disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                        loading={downloadLoader}
                        onClick={() => void handleDownloadReceipts('internal-receipt')}
                      >
                        {intl.formatMessage(pi['pi.details.receiptOption.withFees'])}
                      </Button>
                    </Descriptions.Item>
                    <Descriptions.Item span={3}>
                      <Button
                        icon={<FilePdfOutlined />}
                        type="link"
                        disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                        loading={externalReceiptLoader}
                        onClick={() => void handleDownloadReceipts('external-receipt')}
                      >
                        {intl.formatMessage(pi['pi.details.receiptOption.withoutFees'])}
                      </Button>
                    </Descriptions.Item>
                    <Descriptions.Item span={3}>
                      <Button
                        icon={<FilePdfOutlined />}
                        type="link"
                        disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                        loading={remittanceDownLoader}
                        onClick={() => void handleDownloadReceipts('remittance-advice')}
                      >
                        {intl.formatMessage(pi['pi.details.receiptOption.remittanceAdvice'])}
                      </Button>
                    </Descriptions.Item>
                    <Descriptions.Item span={3}>
                      {handleRemovePayment && (
                        <ACL
                          requiredACL={[
                            { kind: 'charge', action: 'delete' },
                            { kind: 'entity', action: 'delete' },
                          ]}
                          barracksId={payment.profileId}
                        >
                          <Button
                            data-testid="delete-refund-button"
                            icon={<RedoOutlined />}
                            type="link"
                            danger={true}
                            disabled={!['PENDING', 'REQUIRE_SIGNATURE', 'READY'].includes(payment.state!)}
                            onClick={() => handleRemovePayment(payment.id!)}
                          >
                            {refundButtonLabel}
                          </Button>
                        </ACL>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                ),
              },
            ]}
            expandIconPosition="end"
            defaultActiveKey={[1]}
          />
        </div>
      )}
    </Loader>
  )
}

export default PaymentInstructionDetails
