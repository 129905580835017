import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  uppercaseString: css`
    text-transform: uppercase;
  `,
  capitalizeFirstLetter: css`
    text-transform: capitalize;
  `,
}))

export default useStyles
