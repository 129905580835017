import { api } from './utils'
import {
  Iam,
  LanguagePreferences,
  NotificationPreferences,
  NotificationRules,
  PasswordPolicyRule,
  Rules,
  RulesLogic,
  SignChainRule,
  TwoFactorAuthentication,
  TwoFactorAuthenticationRule,
} from '../types/rules'

const BASE_PATH = 'rules'

export const getRules = async (): Promise<Rules> => {
  const url = `/${BASE_PATH}/me`
  const response = await api.get(url)
  return await response.json()
}

export const getRulesBase = async (): Promise<Rules> => {
  const url = `/${BASE_PATH}`
  const response = await api.get(url)
  return await response.json()
}

export const getCountryRules = async (country: string): Promise<Rules> => {
  const url = `/external/${BASE_PATH}/country/${country}`
  const response = await api.getWithoutHeaders(url)
  return await response.json()
}

export const getIam = async (): Promise<Iam[]> => {
  const url = `/${BASE_PATH}/iam`
  const response = await api.get(url)
  return await response.json()
}

export const changeLanguage = async (profileId: string, data: Partial<LanguagePreferences>): Promise<void> => {
  const url = `/${BASE_PATH}/profile/${profileId}`
  const dataFinal = { languagePreferences: data }
  const body = {
    ...dataFinal,
  }

  await api.patch(url, body)
}

export const update2FA = async (profileId: string, data: Partial<TwoFactorAuthentication>): Promise<void> => {
  const url = `/${BASE_PATH}/profile/${profileId}`
  const dataFinal = { twoFactorAuthentication: data }
  const body = {
    ...dataFinal,
  }

  await api.patch(url, body)
}

export const getExternalRules = async (profileId: string, token: string): Promise<NotificationPreferences> => {
  const url = `/external/${BASE_PATH}/profile/${profileId}/rule/notificationPreferences`
  const response = await api.getWithToken(url, token)
  return await response.json()
}

export const getExternalCountryRule = async (countryCode: string): Promise<Rules> => {
  const url = `/external/${BASE_PATH}/country/${countryCode}`
  const response = await api.getWithoutHeaders(url)
  return await response.json()
}

export const updateRules = async (data: Partial<RulesLogic>, profileId: string): Promise<void> => {
  const url = `/${BASE_PATH}/profile/${profileId}`
  await api.patch(url, data)
}

export const updateRulesExternal = async (
  data: Partial<NotificationRules>,
  token: string,
  profileId: string
): Promise<void> => {
  const url = `/external/${BASE_PATH}/profile/${profileId}/rule/notificationPreferences`
  await api.patchWithToken(url, token, data)
}

export const getCountries = async (): Promise<string[]> => {
  const url = `/${BASE_PATH}/constant/country`
  const response = await api.get(url)
  return await response.json()
}

export const getSepaCountries = async (): Promise<{ country: string; bic: string; iban: string }[]> => {
  const url = `/${BASE_PATH}/constant/country/beneficiary/sepa`

  const response = await api.get(url)
  return await response.json()
}

export const getEntityRoles = async (): Promise<string[]> => {
  const url = `/${BASE_PATH}/constant/entity-role`
  const response = await api.get(url)
  return await response.json()
}

export const getProfileRules = async (id: string): Promise<Rules> => {
  const url = `/${BASE_PATH}/profile/${id}`
  try {
    const response = await api.get(url)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getClearingNetworks = async (): Promise<Array<string>> => {
  const url = `/${BASE_PATH}/constant/clearing-network`
  try {
    const response = await api.get(url)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getCardNetworks = async (): Promise<Array<string>> => {
  const url = `/${BASE_PATH}/constant/card-network`
  try {
    const response = await api.get(url)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getCurrencies = async (): Promise<Array<string>> => {
  const url = `/${BASE_PATH}/constant/currency`

  const response = await api.get(url)

  return await response.json()
}

export const getEntityRules = async (entityId: string): Promise<Rules> => {
  const url = `/${BASE_PATH}/entity/${entityId}`
  try {
    const response = await api.get(url)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const searchEntityRules = async (entityIds: string[]): Promise<Record<string, Rules>> => {
  const url = `/${BASE_PATH}/search`
  try {
    const response = await api.post(url, { kind: 'entity', entityIds })

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const updateEntity2FARule = async (data: TwoFactorAuthenticationRule, entityId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}`
  await api.patch(url, { twoFactorAuthentication: { rule: data } })
}

export const updateEntityPasswordPolicyRule = async (data: PasswordPolicyRule, entityId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}`
  await api.patch(url, { passwordPolicy: { rule: data } })
}

export const updateEntitySignChainRule = async (data: SignChainRule[], entityId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}`
  await api.patch(url, { requiredSignChain: { rule: data } })
}

export const updateEntityRules = async (data: Partial<RulesLogic>, entityId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}`

  await api.patch(url, data)
}

export const clearProfileRulesCache = async (profileId: string): Promise<void> => {
  const url = `/${BASE_PATH}/iam/profile/${profileId}/cache`

  await api.delete(url)
}

export const editActiveIntegration = async (vcnPoolId: string, accountName: string): Promise<void> => {
  const url = `/${BASE_PATH}/vcn-pool/airplus/${vcnPoolId}`

  await api.patch(url, { accountName })
}
